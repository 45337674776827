import { h, Component } from "preact";
import { Router } from "preact-router";
import AsyncRoute from 'preact-async-route';
import platform from "mini-platform-detect";

// Code-splitting is automated for routes
import Home from "../routes/home";
import Bulletin from "../routes/bulletin";

import prefs from "../data/prefs";
import { setThemeColor } from "../components";
import "../misc/helper";

export default class App extends Component {
  /** Gets fired when the route changes.
   *	@param {Object} event		"change" event from [preact-router](http://git.io/preact-router)
   *	@param {string} event.url	The newly routed URL
   */

  state = { printing: false };

  componentWillMount() {
    let color = prefs.get(prefs.themeAccentColor);
    if (color && color !== "blue") {
      setThemeColor(color);
    }
  }

  componentDidMount() {
    // prompt user to install app on home screen
    if (platform.chrome) {
      window.addEventListener("beforeinstallprompt", event => {
        // Prevent Chrome <= 67 from automatically showing the prompt
        event.preventDefault();
        event.prompt();
        // Wait for the user to respond to the prompt
        // event.userChoice.then(handleInstall);
      });
    }

  }

  handleRoute = e => {
    this.currentUrl = e.url;
  };

  render() {
    return (
      <div id="app">
        <Router onChange={this.handleRoute}>
          <Home path="/" />
          <Home path="/home/" />
          <Bulletin path="/#:unit" />

          {/* Lazy load some routes */}
          <AsyncRoute path="/editor/" getComponent={() => import("../routes/editor").then(module => module.default)} />
          <AsyncRoute path="/editdemo/" getComponent={() => import("../routes/editor/sample-editor").then(module => module.default)} />
          <AsyncRoute path="/locate/" getComponent={() => import("../routes/locate").then(module => module.default)} />
          <AsyncRoute path="/search/" getComponent={() => import("../routes/search").then(module => module.default)} />
          <AsyncRoute path="/test/" getComponent={() => import('../routes/home/test').then(module => module.default)} />
          <AsyncRoute path="/hymn/:hymn/:language?" getComponent={() => import("../routes/bulletin/hymn-view").then(module => module.default)} />

        </Router>
      </div>
    );
  }
}
