let defaults = {
  recents: [],
  "leader-char": " .",
  "theme-background-color": "system-default",
  "theme-accent-color": "blue",
  "hide-labels": false,
  "home-screen-prompt-time": 0,
  "hymn-app": "ward-bulletin",
  "print-columns":
    `front page: cover
inside left page: announcements calendar
inside right page: program
back page: leaders missionaries lessons information classes`,
  "print-overflow": false,
  "message-read-version": 0,
};

let prefs = {
  cacheBulletin: "cache-bulletin",
  cacheDate: "cache-date",
  cacheId: "cache-id",
  publishDate: "publish-date",
  viewDay: "view-day",
  draftBulletin: "draft-bulletin",
  draftId: "draft-id",
  recents: "recents",
  leaderChar: "leader-char",
  themeBackgroundColor: "theme-background-color",
  themeAccentColor: "theme-accent-color",
  hideLabels: "hide-labels",
  homeScreenPromptTime: "home-screen-prompt-time",
  hymnApp: "hymn-app",
  printColumns: "print-columns",
  printOverflow: "print-overflow",
  messageReadVersion: 'message-read-version',

  get: function (key) {
    let data, dataString;
    if (typeof window !== "undefined") {
      // avoid pre-rendering error
      dataString = localStorage.getItem(key);
    }
    if (dataString === undefined || dataString === null) {
      data = defaults[key];
    } else {
      data = JSON.parse(dataString);
    }
    return data;
  },

  getNumber: function (key) {
    return Number(this.get(key) || 0)
  },

  set: function (key, value) {
    if (typeof window !== "undefined") {
      // avoid pre-rendering error
      localStorage.setItem(key, JSON.stringify(value));
    }
  },

  clear: function (key) {
    if (typeof window !== "undefined") {
      // avoid pre-rendering error
      localStorage.removeItem(key);
    }
  }
};

export function clearCaches() {
  const cacheKeys = ["cacheBulletin", "cacheDate", "cacheId", "publishDate", "viewDay", "draftBulletin", "draftId"]
  cacheKeys.forEach(key => prefs.clear(prefs[key]))
}

export default prefs;
